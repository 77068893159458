import * as streamingAvailability from 'streaming-availability';
import './App.css';
import { useState } from 'react';
import Popup from './Popup';

function App() {
  const [show, setShow] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);
  const [title, setTitle] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    logShows(e.target.title.value);
  };

  const logShows = async (title) => {
    const RAPID_API_KEY = process.env.REACT_APP_API_KEY;
    const client = new streamingAvailability.Client(
      new streamingAvailability.Configuration({
        apiKey: RAPID_API_KEY,
      })
    );
    const data = await client.showsApi.searchShowsByTitle({
      title: title,
      country: 'DE',
    });

    const top5 = data.slice(0, 5);

    const shows = await Promise.all(
      top5.map(async (item) => {
        const result = await client.showsApi.getShow({
          id: item.id,
        });
        return result;
      })
    );

    setShow(shows);
    console.log(shows);
  };

  const openPopup = (show, country) => {
    setSelectedShow(show.streamingOptions[country]);
    setTitle(show.title);
    setIsPopupOpen(true);
  };


  return (
    <div className="App">
      <header className="App-header">
        <h1>Streaming Availability</h1>
      </header>
      <form onSubmit={handleSubmit}>
        <input
          id="title"
          type="text"
          placeholder="Search for a movie or TV show"
        />
        <button type="submit">Search</button>
      </form>
      <div className="results">
        {show.map((item) => (
          <div key={item.id} className="result-item">
            <h2>{item.title}</h2>
            <p>{item.overview}</p>
            <img src={item.imageSet.verticalPoster.w240} alt={item.title} />
            <div className="streaming-options">
              {Object.keys(item.streamingOptions)
                .filter((country) => {
                  const options = item.streamingOptions[country];
                  return options.some(
                    (option) =>
                      option.type === 'subscription' ||(option.type === 'addon' )
                  );
                })
                .map((country) => (
                  <div
                    key={country}
                    className="country"
                    style={{
                      display: 'inline-block',
                      margin: '10px',
                      position: 'relative',
                    }}
                    title={new Intl.DisplayNames(['en'], { type: 'region' }).of(
                      country.toUpperCase()
                    )}
                  >
                    <img
                      src={`https://flagcdn.com/32x24/${country.toLowerCase()}.png`}
                      onClick={() => openPopup(item, country)}
                      alt={country}
                    />
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      <Popup open={isPopupOpen} title={title} show={selectedShow} close={(_) => setIsPopupOpen(false)} />
    </div>
  );
}

export default App;
