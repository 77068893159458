import React from 'react';
import './Popup.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function Popup({ show, close, title, open }) {
  if (!show) return null;


  const filteredShows = show.filter(
    (data) => data.type === 'subscription' || data.type === 'addon'
  );

  const uniqueAddons = new Map();

  filteredShows.forEach((data) => {
    if (data.type === 'addon' && data.addon.id !== 'tvs.sbd.12962') {
      if (!uniqueAddons.has(data.addon.id)) {
        uniqueAddons.set(data.addon.id, data);
      }
    }
  });

  const finalShows = [
    ...filteredShows.filter((data) => data.type === 'subscription'),
    ...Array.from(uniqueAddons.values()),
  ];

  console.log(show);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{
          textAlign: 'center',
          backgroundColor: 'white',
          color: 'black',
          paddingBottom: '0',
        }}
        id="alert-dialog-title"
      >
        <CloseRoundedIcon
          onClick={close}
          className="closeDialog"
          style={{
            position: 'absolute',
            top: '1vh',
            right: '1vh',
            borderRadius: '10px',
            width: '2rem',
            height: 'auto',
            backgroundColor: 'white',
          }}
        />
        <p
          id="dialog-title"
          style={{
            margin: 'auto',
            textAlign: 'center',
            color: 'black',
            width: '90%',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </p>
      </DialogTitle>
      <DialogContent
        id="alert-dialog-description"
        style={{ backgroundColor: 'white', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center' }}
      >
        {finalShows.map((data, index) => (
          <div key={index} className="streaming-service">
            <div className="image-container">
              <img
                src={
                  data.type === 'addon'
                    ? data.addon.imageSet.lightThemeImage ||
                      data.service.imageSet.lightThemeImage
                    : data.service.imageSet.lightThemeImage
                }
                alt={
                  data.type === 'addon' ? data.addon.name : data.service.name
                }
                className="centered-image"
              />
            </div>
            <p>
              Sprache: {data.audios.map((audio) => audio.language).join(', ')}
            </p>
            <p>
              Untertitel:{' '}
              {data.subtitles
                .map((subtitle) => subtitle.locale.language)
                .join(', ')}
            </p>
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export default Popup;
